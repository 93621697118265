import React, { Component } from 'react'
import posed, { PoseGroup } from 'react-pose'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import Navbar from '../components/nav'
import DownloadForm from './utils/downloadForm'
import { Fade, DefaultPose } from './poses'
import './bootstrap.min.css'
import '../scss/main.scss'
import fb from '../images/fb.jpeg'

export const ModalContext = React.createContext('aye')

class Page extends Component {
  state = { displayDownloadForm: false }
  handleCloseForm = () => {
    this.setState({ displayDownloadForm: false })
  }
  handleShowForm = () => {
    this.setState({ displayDownloadForm: true })
  }
  render() {
    const { pose, transitionComponent } = this.props.element
    let transition = DefaultPose
    if (
      this.props.location.state &&
      this.props.location.state.transition === 'fade'
    ) {
      transition = Fade
    }
    const Pose = pose ? posed.div(pose) : transition

    const UsedTransition = transitionComponent || Pose
    return (
      <div>
        <Helmet title="MMC Ventures presents The State of AI 2019">
          <meta property="og:image" content={fb} />
          <meta name="twitter:image:src" content={fb} />
          <meta property="fb:app_id" content="316457869057200" />
        </Helmet>
        <ModalContext.Provider value={this.handleShowForm}>
          <Navbar
            displayDownloadForm={this.state.displayDownloadForm}
            onShowModal={this.handleShowForm}
            data={this.props}
          />
        </ModalContext.Provider>
        <DownloadForm
          displayDownloadForm={this.state.displayDownloadForm}
          onShowForm={this.handleShowForm}
          onCloseForm={this.handleCloseForm}
        />
        <div className="site-wrapper">
          <PoseGroup
            displayForm={this.handleShowForm}
            {...this.state}
            previous_order={
              this.props.location.state
                ? this.props.location.state.previous_order
                : '0'
            }
            order_scroll={
              this.props.location.state
                ? this.props.location.state.order_scroll
                : '0'
            }
          >
            <UsedTransition
              key={this.props.location.pathname}
              displayForm={this.handleShowForm}
            >
              <ModalContext.Provider value={this.handleShowForm}>
                <div className="section-wrapper">
                  <div className="container-fluid">{this.props.element}</div>
                </div>
              </ModalContext.Provider>
            </UsedTransition>
          </PoseGroup>
        </div>
        <CookieConsent
          location="bottom"
          buttonText="Got it!"
          buttonClasses="solidButton"
          cookieName="acceptCookies"
          disableStyles={true}
          style={{
            background: '#fff',
            opacity: '.9',
            color: '#000',
            zIndex: 1000,
            width: '100%',
            textAlign: 'center',
            padding: 10,
          }}
          contentStyle={{
            display: 'inline-block',
          }}
          buttonStyle={{
            textTransform: 'uppercase',
            color: '#000',
            fontSize: '13px',
            padding: '10px 30px',
            margin: 10,
          }}
          expires={150}
        >
          This website uses cookies to give you the best experience. For the
          full details, read our <Link to="/cookie-policy">Cookie Policy</Link>
        </CookieConsent>
      </div>
    )
  }
}

export default Page

export const WrapPageElement = ({ element, props, state }) => {
  return <Page element={element} {...props} {...state} />
}
