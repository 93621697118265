import React, { Component } from 'react'
import SolidButton from '../buttons/SolidButton'
import _variables from '../../scss/common/_variables.scss'
import axios from 'axios'

class DownloadForm extends Component {
  constructor(props) {
    super(props)
    this.handleDownloadClick = this.handleDownloadClick.bind(this)
    this.state = {
      download: false,
      inputValueName: '',
      inputValueEmail: '',
      inputValueTerms: false,
      inputValueSubscribe: false,
      busy: false,
      formError: '',
    }
  }

  handleDownloadClick(e) {
    e.preventDefault()
    this.setState({ busy: true })
    const subscriber = {}
    subscriber['name'] = this.state.inputValueName
    subscriber['email'] = this.state.inputValueEmail
    subscriber['terms'] = this.state.inputValueTerms
    subscriber['subscribe'] = this.state.inputValueSubscribe

    if (this.state.inputValueEmail === '' || this.state.inputValueName === '') {
      this.setState({ formError: 'You must type your name and email address' })
      this.setState({ busy: false })
    } else if (!this.state.inputValueTerms) {
      this.setState({ formError: 'You must agree to the terms and conditions' })
      this.setState({ busy: false })
    } else {
      this.setState({ formError: '' })
      this.setState({ download: true })

      axios
        .post('https://cms.stateofai2019.com/campaign.php', subscriber)
        .then(response => {
          if (response.data.type === 'TERMS') {
            this.setState({ busy: false })
            this.setState({ formError: response.data.message })
          } else if (response.data.type === 'NAME') {
            this.setState({ formError: response.data.message })
            this.setState({ busy: false })
          } else {
            this.setState({ formError: '' })
            this.setState({ download: true })

            axios({
              url: 'https://cms.stateofai2019.com/pdfs/SoAI2019_Report_WEB.pdf',
              method: 'GET',
              responseType: 'blob', // important
            })
              .then(response => {
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'SoAI2019_Report_WEB.pdf')
                document.body.appendChild(link)
                link.click()
                this.setState({ busy: false })
              })
              .catch(error => {
                this.setState({
                  formError: 'Oops! Something went wrong. Please try again.',
                  download: false,
                  busy: false,
                })
              })
          }
        })
        .catch(error => {
          this.setState({
            formError: 'Oops! Something went wrong. Please try again.',
            download: false,
            busy: false,
          })
        })
    }
  }
  updateInputNameValue(evt) {
    this.setState({
      inputValueName: evt.target.value,
    })
  }
  updateInputEmailValue(evt) {
    this.setState({
      inputValueEmail: evt.target.value,
    })
  }
  updateInputTermsValue = () => {
    this.setState({ inputValueTerms: !this.state.inputValueTerms })
  }
  // updateInputSubscribeValue = () => {
  //   this.setState({ inputValueSubscribe: !this.state.inputValueSubscribe })
  // }
  render() {
    let formClass = ''
    let modalClass = ''
    if (!this.props.displayDownloadForm) {
      formClass = ' hidden'
      modalClass = ' hidden'
    } else {
      formClass = ''
      modalClass = ' zoomIn'
    }
    return (
      <React.Fragment>
        <div>
          <div className={`overlay ${formClass}`} />
          <div className={`downloadForm ${modalClass}`}>
            <i
              className="fas fa-times"
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                fontSize: 25,
                cursor: 'pointer',
              }}
              onClick={this.props.onCloseForm}
            />
            <form>
              {this.state.download ? (
                <React.Fragment>
                  <h1>Thank you</h1>
                  <p>Your download will begin shortly.</p>
                  <div
                    onClick={this.props.onCloseForm}
                    className="solidButton"
                    style={{
                      padding: '10px 20px',
                      fontWeight: '600',
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      width: 'auto',
                      cursor: 'pointer',
                    }}
                  >
                    OK
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h1>Download the report</h1>
                  {this.state.formError && (
                    <p style={{ color: 'red' }}>{this.state.formError}</p>
                  )}
                  <p>
                    Enter your name and email below to download the full report.
                  </p>

                  <div className="formElem">
                    <p style={{ position: 'relative' }}>
                      <input
                        className="form-control"
                        type="name"
                        name="name"
                        id="name"
                        value={this.state.inputValueName}
                        onChange={evt => this.updateInputNameValue(evt)}
                        placeholder="Your name"
                      />
                      <span>*</span>
                    </p>
                    <p style={{ position: 'relative' }}>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={this.state.inputValueEmail}
                        onChange={evt => this.updateInputEmailValue(evt)}
                        id="email"
                        placeholder="Your email"
                      />
                      <span>*</span>
                    </p>
                    <p>
                      <label htmlFor="terms">
                        <input
                          type="checkbox"
                          name="terms"
                          id="terms"
                          checked={this.state.inputValueTerms}
                          value={this.state.inputValueTerms}
                          onChange={this.updateInputTermsValue}
                        />{' '}
                        I agree to the <a href="/terms-and-conditions" target="_blank">terms &amp; conditions</a> *
                      </label>
                    </p>
                    {/* <p>
                      <label htmlFor="mailingList">
                        <input
                          type="checkbox"
                          name="mailingList"
                          id="mailingList"
                          value={this.state.inputValueSubscribe}
                          onChange={this.updateInputSubscribeValue}
                        />{' '}
                        I’d like to receive occasional updates and news from MMC
                        Ventures
                      </label>
                    </p> */}
                  </div>
                  <button
                    onClick={e => {
                      this.handleDownloadClick(e)
                    }}
                    disabled={this.state.busy}
                    className="solidButton"
                    style={{
                      padding: '10px 20px',
                      fontWeight: '600',
                      textTransform: 'uppercase',
                      textAlign: 'center',
                      width: 'auto',
                      cursor: 'pointer',
                      marginTop: 20,
                    }}
                  >
                    {this.state.busy ? (
                      <React.Fragment>
                        <i class="fas fa-spinner fa-spin" />
                        &nbsp;&nbsp;Download the report
                      </React.Fragment>
                    ) : (
                      'Download the report'
                    )}
                  </button>

                  <p
                    style={{
                      paddingTop: 20,
                      fontSize: '.8em',
                    }}
                  >
                    By downloading the report, you agree to receiving news and
                    updates from MMC Ventures.
                  </p>
                </React.Fragment>
              )}
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DownloadForm
